import { convertClock } from "../ConvertClock";
import { differenceInSeconds } from "../Date";
import { toCatchupFilter } from "./ToCatchupFilter";

export async function multiDeviceClockCatchup(messages, currentState, updateState, fixtureProfile) {
  let tempPeriod = currentState.period ? currentState.period : { periodId: 0 };
  let tempClock = currentState.clock ? JSON.parse(JSON.stringify(currentState.clock)) : {};

  const toCatchupEvents = toCatchupFilter(messages, currentState).sort(function (a, b) {
    var aMixed = new Date(a.eventTime.replace("Z", ""));
    var bMixed = new Date(b.eventTime.replace("Z", ""));
    return aMixed < bMixed || a.periodId < b.periodId ? -1 : 1;
  });

  toCatchupEvents.forEach((event) => {
    if (event.periodId >= tempPeriod.periodId) {
      tempPeriod.periodId = event.periodId;
    }

    const handlePeriodEvent = () => {
      tempPeriod.periodId = event.periodId;
      switch (event.subType) {
        case "start":
          tempPeriod.periodStatus = "inProgress";
          break;
        case "end":
          tempPeriod.periodStatus = "periodEnd";
          break;
        case "confirmed":
          if (tempPeriod.periodId >= fixtureProfile.numberOfPeriods) {
            tempPeriod.periodStatus = "pendingExtra";
          } else {
            tempPeriod.periodStatus = "pending";
            tempPeriod.periodId = tempPeriod.periodId + 1;
          }
          break;
        case "pending":
          if (event.periodId > 0) {
            tempPeriod.periodStatus = "pending";
            tempClock.displayTime = convertClock("PT0M0S");
          }
          break;
        default:
          break;
      }
    };

    const handleFixtureEvent = () => {
      switch (event.subType) {
        case "end":
          tempPeriod.periodStatus = "fixtureConfirm";
          break;
        case "confirmed":
          tempPeriod.periodStatus = "fixtureComplete";
          break;
        default:
          break;
      }
    };

    const handleClockMainEvent = () => {
      if (event.class === "clock") {
        if (event.subType === "stop") {
          tempClock.clockRunning = false;
          tempClock.displayTime = convertClock(event.clock);
        }
        if (event.subType === "start") {
          tempClock.clockRunning = true;
          const deltaTime = Math.floor(
            differenceInSeconds(new Date(), new Date(`${event.eventTime}${event.eventTime.includes("Z") ? "" : "Z"}`)),
          );
          const splitTime = convertClock(event.clock).split(":");
          const allSeconds = parseInt(splitTime[0], 10) * 60 + parseInt(splitTime[1], 10) + deltaTime;
          const newMins = Math.floor(allSeconds / 60);
          const newSecs = allSeconds % 60;
          tempClock.displayTime = newMins + ":" + newSecs;
        }
      }
    };

    switch (event.eventType) {
      case "period":
        handlePeriodEvent();
        break;
      case "fixture":
        handleFixtureEvent();
        break;
      case "main":
        handleClockMainEvent();
        break;
      default:
        break;
    }
  });

  if (toCatchupEvents.length > 0) {
    updateState("periodCatchup", tempPeriod);
    updateState("clockCatchup", tempClock);
  }
}
