import React, { useContext, useMemo } from "react";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import { sendPossessionEvent } from "../../Base/Utilities/PossessionEvents";
import SinBin from "./SinBin";

function Team(props) {
  const { entity, panel, currentState, intl, updateState, index } = props;
  const { mqtt, entities, period } = currentState;
  const { formatMessage } = intl;
  const {
    fixtureProfile: { foulsBeforeBonus, foulsBeforeBonusOvertime, initialExtraPeriodId },
    sport,
  } = useContext(FixtureDetailsContext);

  function isTeamAtOrOverFoulBonus(team, sport, period) {
    let expectedFoulsCount = foulsBeforeBonus;
    if (sport === 'basketball'
      && foulsBeforeBonusOvertime
      && period.periodId >= initialExtraPeriodId
    ) {
       expectedFoulsCount = foulsBeforeBonusOvertime
    }

    return team.fouls >= expectedFoulsCount;
  }

  function setValue(variable) {
    let tempEntities = entities;
    let currentEntity = entity.entityId;

    tempEntities[tempEntities.findIndex((el) => el.entityId !== currentEntity)][
      variable
    ] = false;
    tempEntities[tempEntities.findIndex((el) => el.entityId === currentEntity)][
      variable
    ] = true;
    sendPossessionEvent(
      tempEntities[
        tempEntities.findIndex((el) => el.entityId === currentEntity)
      ]["entityId"],
      variable,
      props
    );
    updateState("entities", tempEntities);
  }

  const shootoutScore = useMemo(() => {
    if (!entities) {
      return null;
    }

    const isShootoutEnded = mqtt.plays?.some(
      (event) => event.eventType === "shootOut" && event.subType === "end"
    );

    if (!isShootoutEnded) {
      return null;
    }

    return mqtt.plays.reduce(
      (soFar, curr) => {
        if (curr.eventType !== "shootOutAttempt") {
          return soFar;
        }

        return {
          ...soFar,
          [curr.entityId]: soFar[curr.entityId] + (curr.success ? 1 : 0),
        };
      },
      {
        [entities[0].entityId]: 0,
        [entities[1].entityId]: 0,
      }
    );
  }, [mqtt.plays, entities]);

  return (
    <div
      className={
        `team team-${index}` +
        " hasPossession-" +
        entity.hasPossession +
        " hasArrow-" +
        entity.hasArrow
      }
      style={{
        borderColor: entity.primaryColor,
      }}
      key={entity.entityId}
    >
      {panel.showSinBin && <SinBin {...props} />}
      <div className="teams-top-row">
        <div className={"team-score recovery-" + currentState.recovery}>
          {!panel.hideScore && (
            <>
              {entity.score}
              {shootoutScore && <span>({shootoutScore[entity.entityId]})</span>}
            </>
          )}
        </div>
        <div className="team-notes">
          {panel.showArrow && (
            <div className="arrow" onClick={() => setValue("hasArrow")}>
              <i className="fas fa-caret-left left-arrow"></i>
              <i className="fas fa-caret-right right-arrow"></i>
            </div>
          )}
          {panel.showPossessionIcon && (
            <div
              className={`possession possession-${sport}`}
              onClick={() => setValue("hasPossession")}
            >
              <i className={`fas fa-${panel.possessionIcon}`}></i>
            </div>
          )}
        </div>
        <div className="team-name">
          <span>{entity.fullNameLatin}</span>
        </div>
      </div>
      {panel.showStats && (
        <div className="team-stats">
          <div
            className={`team-fouls ${
              isTeamAtOrOverFoulBonus(entity, sport, period) ? "team-fouls-bonus" : null
            }`}
          >
            <span>
              {formatMessage({
                id: "fouls.abbreviation",
                defaultMessage: "Fouls",
              })}
              :
            </span>
            {isNaN(entity.fouls) ? 0 : entity.fouls}
          </div>
          <div className="team-timeouts">
            <span>
              {formatMessage({
                id: "timeouts.abbreviation",
                defaultMessage: "TO",
              })}
              :
            </span>
            {isNaN(entity.timeouts) ? 0 : entity.timeouts}
          </div>
        </div>
      )}
    </div>
  );
}

export default Team;
