import FIBAWorkflow from "./basketball/FIBAWorkflow";
import Ice3Workflow from "./ice3/Ice3Workflow";
import HockeyWorkflow from "./hockey/HockeyWorkflow";
import HandballWorkflow from "./handball/HandballWorkflow";
import HockeyClockOperatorWorkflow from "./hockey/HockeyClockOperatorWorkflow";
import HockeyScoreOperatorWorkflow from "./hockey/HockeyScoreOperatorWorkflow";
import SimpleBasketballWorkflow from "./basketball/SimpleBasketballWorkflow";
import { SIMPLE_BASKETBALL_ORG } from "../Config/Environment";

const getWorkflowForFixture = ({ sport, userMode, organizationId }) => {
  if (sport === "basketball") {
    const grassrootsOrgIds = SIMPLE_BASKETBALL_ORG.split(",")
    if (grassrootsOrgIds.includes(organizationId)) {
      return SimpleBasketballWorkflow;
    }
    return FIBAWorkflow;
  }
  if (sport === "ice3") {
    return Ice3Workflow;
  }
  if (sport === "hockey") {
    if (userMode === "clockOperator") {
      return HockeyClockOperatorWorkflow;
    }

    if (userMode === "scoreOperator") {
      return HockeyScoreOperatorWorkflow;
    }
    return HockeyWorkflow;
  }

  if (sport === "handball") {
    return HandballWorkflow;
  }
};

export { getWorkflowForFixture };
