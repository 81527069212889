const saveShotPlayerToFoulDrawn = [
  { action: "copyEvent", value: "shot", type: "from" },
  { action: "clearData", value: ["options"], type: "main" },
  { action: "saveData", value: "foul", type: "eventType" },
  { action: "saveData", value: "drawn", type: "subType" },
  { action: "copyEvent", value: "fouldrawn", type: "to" },
];

export const And1FoulPlayerSelect = {
  name: "and1-foul-player-select",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "foul.select-player",
  showOn: [{ state: "primary", value: "and1-foul-player-select", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "and1-foul-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: false,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            {
              action: "saveData",
              value: [{ option: "freeThrows", value: 1 }],
              type: "options",
            },
          ],
          conditions: [{ item: "success", value: true, event: "shot", operator: "is" }],
        },
        {
          actions: [
            { action: "saveData", value: "foul", type: "eventType" },
            { action: "saveData", value: "personal", type: "subType" },
            {
              action: "saveData",
              value: [{ option: "shooting", value: true }],
              type: "options",
            },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "foul", type: "to" },
            ...saveShotPlayerToFoulDrawn,
            { action: "copyEvent", value: "foul", type: "from" },
            { action: "nextState", value: "free-throws-select-locked", type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
