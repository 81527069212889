import React, { useContext, useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import handballCourt from "../../../assets/handball/court.png";

import { useIsVisible } from "../../Base/Hooks/IsVisible";
import { useDoActions } from "../../Base/Hooks/doActions";
import "./LocationEditor.scss";
import PositionHelper from "../../Base/Utilities/PositionHelper.js";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails.jsx";

const LocationEditor = (props) => {
  const { panel, updateState, currentState, intl } = props;
  const { formatMessage } = intl;
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [doAction, setDoAction] = useState(false);
  const [markerPos, setMarkerPos] = useState(null);
  const [actionsDone] = useDoActions(panel, props, doAction);
  const { sport } = useContext(FixtureDetailsContext);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
    // eslint-disable-next-line
  }, [actionsDone]);

  useEffect(() => {
    const posX = currentState.events?.edit?.x ?? "0";
    const posY = currentState.events?.edit?.y ?? "0";
    setMarkerPos({ x: posX, y: posY });
    // eslint-disable-next-line
  }, []);

  const handlePositionClick = (e) => {
    let posX = parseFloat(((e.nativeEvent.offsetX / e.target.offsetWidth) * 100).toFixed(2));
    let posY = parseFloat(((e.nativeEvent.offsetY / e.target.offsetHeight) * 100).toFixed(2));
    setMarkerPos({ x: posX, y: posY });
  };

  const handleSave = () => {
    const teamIndex = currentState.entities.findIndex((entity) => entity.entityId === currentState.events.edit.entityId);
    const point = PositionHelper.getPoint(markerPos.x, markerPos.y, sport);
    const regionData = PositionHelper.getRegionData(teamIndex, point)
    const tempEvents = currentState.events;

    tempEvents.edit.x = markerPos.x;
    tempEvents.edit.y = markerPos.y;
    tempEvents.edit.options.location = regionData.location;
    tempEvents.edit.subType = regionData?.subType;
    updateState("events", tempEvents);
    setDoAction(true);
  };

  return (
    <React.Fragment>
      {isVisible && (
        <div className={panel.layout + " location-editor-root enabled-" + isEnabled}>
          <div className={"location-editor-main"} onClick={(e) => handlePositionClick(e)}>
            <img className="location-editor-court" src={handballCourt} />
            <div
              className="location-editor-marker"
              style={{
                top: markerPos.y - 2.0 + "%",
                left: markerPos.x - 1.0 + "%",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            ></div>
          </div>
          <div className="location-editor-save-button" onClick={handleSave}>
            {formatMessage({
              id: "location-editor.save",
              defaultMessage: "Save new location",
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(LocationEditor);
