export function storeCatchupDoneEvent(event, currentState, updateState) {
  var catchupDoneEvents = { ...(currentState.catchupDoneEvents ?? {}) };
  catchupDoneEvents[event.eventId] = event.timestamp;
  updateState("catchupDoneEvents", catchupDoneEvents);
}

export function storeCatchupDoneEvents(events, currentState, updateState) {
  var catchupDoneEvents = { ...(currentState.catchupDoneEvents ?? {}) };
  events.forEach((event) => {
    catchupDoneEvents[event.eventId] = event.timestamp;
  });
  updateState("catchupDoneEvents", catchupDoneEvents);
}
