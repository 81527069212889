import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDoActions } from "../../../Base/Hooks/doActions";
import { useIsVisible } from "../../../Base/Hooks/IsVisible";
import { sendEvent } from "../../../Base/Utilities/SendEvent";
import { sendPossessionEvent } from "../../../Base/Utilities/PossessionEvents";
import PositionHelper from "../../../Base/Utilities/PositionHelper.js";

const MadeMissedHandball = (props) => {
  const { team, success, style, panel, intl, currentState, updateState } =
    props;
  const { formatMessage } = intl;
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);

  const getActiveGoalKeepers = () => {
    const defendingTeam = team === 0 ? 1 : 0;
    return currentState.entities[defendingTeam].persons.filter(
      (p) => p.position === "G" && p.active
    );
  };

  const isNetEmpty = () => {
    return getActiveGoalKeepers().length === 0;
  };

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
      const currentEvents = currentState.events;
      currentEvents["main"]["entityId"] = currentState.entities[team].entityId;
      currentEvents["main"]["success"] = success === 1;
      currentEvents["main"]["options"] = {};

      const regionData = PositionHelper.getRegionData(team, currentState.region);
      currentEvents.main.options.location = regionData.location;
      currentEvents.main.subType = regionData?.subType;

      if (isNetEmpty()) {
        currentEvents["main"]["options"]["emptyNet"] = isNetEmpty();
      } else {
        currentEvents["main"]["options"]["goalKeeperId"] =
          getActiveGoalKeepers()[0].personId;
      }

      updateState("events", currentEvents);

      if (panel.options && panel.options.send) {
        const action = {
          action: "sendEvent",
          value: panel.options.send,
          type: "main",
        };
        sendEvent(action, props);
      }

      if (currentState?.insertMode?.enabled !== true
        && panel.options?.changePossession === true
      ) {
        sendPossessionEvent(
          currentState.entities.find(entity =>
            entity.entityId !== currentEvents["main"]["entityId"]
          ).entityId,
          "hasPossession",
          props,
          true
        );

        const tempEntities = currentState.entities;
        tempEntities[0].hasPossession = !tempEntities[0].hasPossession;
        tempEntities[1].hasPossession = !tempEntities[1].hasPossession;

        updateState("entities", tempEntities);
      }
    }
    // eslint-disable-next-line
  }, [actionsDone]);

  return (
    <>
      {isVisible && (
        <div
          className={
            panel.layout + " enabled-" + isEnabled + " success-" + success
          }
          style={style}
          onClick={() => setDoAction(true)}
        >
          <span>
            {formatMessage({ id: panel.text, defaultMessage: panel.text })}{" "}
          </span>
        </div>
      )}
    </>
  );
};

export default injectIntl(MadeMissedHandball);
