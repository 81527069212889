import React, { useEffect, useState, useMemo, useContext } from "react";
import { injectIntl } from "react-intl";

import {
  getEventTimeForUpdatedDeletedEvents,
  sendEvent,
} from "../../Base/Utilities/SendEvent";

import DeleteListItem from "./DeleteListItem/DeleteListItem";
import { convertClock } from "../../Base/Utilities/ConvertClock";

import "./DeletePanel.scss";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails.jsx";
import { generatePeriodTitle } from "../../Base/Utilities/Game.js";

const DeletePanel = (props) => {
  const { currentState, updateState, intl } = props;
  const { formatMessage } = intl;
  const [deleteEvents, setDeleteEvents] = useState([]);
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);

  useEffect(() => {
    if (currentState.deleteMode && currentState.deleteMode.enabled) {
      if (currentState.deleteMode.currentType.name === "videoReview") {
        setDeleteEvents(
          getVideoReviewEventsToDelete(
            currentState.deleteMode.event,
            props.eventStore
          ),
        );
      } else if (currentState.deleteMode.currentType.delete_all) {
        let allEvents = props.eventStore.filter(
          (ev) => ev.playId === currentState.deleteMode.event.playId
        );
        setDeleteEvents(allEvents.filter((ev) => ev.status !== "deleted"));
      } else {
        setDeleteEvents([currentState.deleteMode.event]);
      }
    }
    // eslint-disable-next-line
  }, [currentState.deleteMode]);

  const DeletePanels = useMemo(() => {
    if (deleteEvents.length > 0) {
      return uniqueEvents(deleteEvents, (ev) => ev.eventId).map((item) => (
        <DeleteListItem
          className="deleteEventListItem"
          key={item.eventId}
          event={item}
          {...props}
        />
      ));
    }
    // eslint-disable-next-line
  }, [deleteEvents]);

  function cancelInsert() {
    // Clean Up and Eject
    updateState("deleteMode", null);
    // updateState("processInserts", null);
    // updateState("insertMode", { enabled: false });
  }

  function doDeletes() {
    deleteEvents.forEach((event) => {
      event.status = "deleted";
      event.eventTime = getEventTimeForUpdatedDeletedEvents(event);
      let action = {
        action: "sendEvent",
        event: event,
        type: "custom",
      };

      sendEvent(action, props);
    });
    if (currentState.deleteMode.currentType.delete_all) {
      updateState("modify", null);
    }
    updateState("deleteMode", { enabled: false });
    updateState("confirmArrows", true);
  }

  function uniqueEvents(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  const getVideoReviewEventsToDelete = (currentEvent, eventStore) => {
    const eventsToDelete = [currentEvent];
    if (currentEvent.subType === "start") {
      const videoReviewEvents = eventStore.filter(
        (ev) => ev.eventType === "videoReview",
      );
      const startReviewEventIndex = videoReviewEvents.findIndex(
        event => event.eventId === currentEvent.eventId,
      );
      const endReviewEventIndex = videoReviewEvents.findIndex(
        (obj, index) => index === startReviewEventIndex + 1
          && obj.subType === "end",
      );

      if (endReviewEventIndex !== -1) {
        eventsToDelete.push(videoReviewEvents[endReviewEventIndex]);
      }
    }

    return eventsToDelete;
  };

  return (
    <React.Fragment>
      {currentState.deleteMode && currentState.deleteMode.enabled && (
        <div className="delete-panel">
          <div>
            <h3>
              {formatMessage({
                id: "events.delete",
                defaultMessage: "Delete Events",
              })}
              :
              <span className="period-details">
                {generatePeriodTitle(sport, currentState.deleteMode.event.periodId, fixtureProfile, formatMessage, false)}
              </span>
              <span className="play-clock">
                {currentState.deleteMode.event.clock &&
                  convertClock(currentState.deleteMode.event.clock)}
              </span>
            </h3>
            <h4>
              {formatMessage({
                id: currentState.deleteMode.currentType.delete_all
                  ? "events.delete.description.all"
                  : "events.delete.description",
                defaultMessage: "Are you sure? ",
              })}
            </h4>
            <div className="event-list">{DeletePanels}</div>
            <div className="match-buttons">
              <div
                className="enabled-true match-button missed"
                onClick={() => cancelInsert()}
              >
                {formatMessage({
                  id: "cancel",
                  defaultMessage: "Cancel",
                })}
              </div>
              <div
                className="enabled-true match-button made"
                onClick={() => doDeletes()}
              >
                {formatMessage({
                  id: "continue",
                  defaultMessage: "Continue",
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(DeletePanel);
