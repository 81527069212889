class PositionHelper {
  static regionsForSport = {
    basketball: [
      { name: "P1", value: "0000ff" },
      { name: "P1", value: "0055aa" },
      { name: "P1", value: "0053ac" },
      { name: "C1", value: "00ff00" },
      { name: "C1", value: "00a956" },
      { name: "M", value: "00ffff" },
      { name: "P2", value: "ffff00" },
      { name: "P2", value: "ffac00" },
      { name: "P2", value: "ffaa00" },
      { name: "C2", value: "ff0000" },
    ],
    handball: [
      { name: "RW1", value: "2a4cd7" },
      { name: "6MR1", value: "814a19" },
      { name: "6MC1", value: "000000" },
      { name: "6ML1", value: "29d0d0" },
      { name: "LW1", value: "ad2224" },
      { name: "9MR1", value: "9dafff" },
      { name: "9MC1", value: "81c57a" },
      { name: "9ML1", value: "8126c0" },
      { name: "RW2", value: "1d6914" },
      { name: "6MR2", value: "e9debb" },
      { name: "6MC2", value: "575757" },
      { name: "6ML2", value: "000080" },
      { name: "LW2", value: "ffcdf3" },
      { name: "9MR2", value: "ffee32" },
      { name: "9MC2", value: "ff9233" },
      { name: "9ML2", value: "a0a0a0" },
      { name: "OUT", value: "ffffff" },
    ],
  };

  static fieldZones = {
    "LW": { subType: "wing", location: "LEFT" },
    "RW": { subType: "wing", location: "RIGHT" },
    "6ML": { subType: "sixMetre", location: "LEFT" },
    "6MR": { subType: "sixMetre", location: "RIGHT" },
    "6MC": { subType: "sixMetre", location: "CENTRE" },
    "9ML": { subType: "nineMetre", location: "LEFT" },
    "9MR": { subType: "nineMetre", location: "RIGHT" },
    "9MC": { subType: "nineMetre", location: "CENTRE" },
  };

  static componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  static rgbToHex(r, g, b) {
    return this.componentToHex(r) + this.componentToHex(g) +
      this.componentToHex(b);
  }

  static getRegionData(team, region) {
    const result = {};

    if ((team === 0 && region.at(-1) !== "2") ||
      (team === 1 && region.at(-1) !== "1")
    ) {
      result.subType = "backCourt";
    } else {
      const regionZone = region.slice(0, region.length - 1);
      result.subType = this.fieldZones[regionZone].subType;
      result.location = this.fieldZones[regionZone].location;
    }

    return result;
  }

  static getPoint(posX, posY, sport) {
    const regions = this.regionsForSport[sport] ?? [];
    const img = document.getElementById("base-play-area-regions");
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const pointX = (canvas.width / 100) * posX;
    const pointY = (canvas.height / 100) * posY;

    canvas.getContext("2d").drawImage(img, 0, 0, img.width, img.height);
    const pixelData = canvas.getContext("2d").
      getImageData(pointX, pointY, 1, 1).data;
    canvas.remove();
    const region = regions.find(
      (r) => r.value === this.rgbToHex(pixelData[0], pixelData[1], pixelData[2])
    );

    return region ? region.name : "M";
  }
}

export default PositionHelper;