import { toCatchupFilter } from "./ToCatchupFilter";

export async function possessionCatchup(messages, currentState, updateState) {
  let tempEntities = currentState.entities;

  if (!tempEntities.length) {
    return;
  }

  const possessionEvents = toCatchupFilter(messages, currentState).filter((msg) => msg.eventType === "possession");

  if (!possessionEvents.length) {
    return;
  }

  const lastPossessionEvent = possessionEvents[possessionEvents.length - 1];

  tempEntities.find((team) => team.entityId === lastPossessionEvent.entityId).hasPossession = true;
  tempEntities.find((team) => team.entityId !== lastPossessionEvent.entityId).hasPossession = false;
  updateState("entities", tempEntities);
}
