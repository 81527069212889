import React, { useContext, useState } from "react";
import { Input } from "reactstrap";
import Button from "../Button/Button.jsx";
import { sendAttendance } from "../Utilities/ManagePlayers.js";
import Modal from "./Modal.jsx";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails.jsx";

function AttendanceModal(props) {
  const { formatMessage, setShowAttendanceModal, currentState } = props;
  const { fixtureProfile }  = useContext(FixtureDetailsContext);
  const context = useContext(FixtureDetailsContext);
  const [attendanceCurrent, setAttendanceCurrent] = useState(context?.attendance || 0);
  const [attendance, setAttendance] = useState(context?.attendance || 0);

  const confirmHandler = () => {
    const attendanceNew = document.getElementById("attendance").value;
    sendAttendance(
      parseInt(attendanceNew),
      currentState.mqtt,
      currentState.fixtureId,
      fixtureProfile
    );
    setAttendance(attendanceNew);
    setShowAttendanceModal(false);
  }

  const changeHandler = (e) => {
    const newAttendance = parseInt(e.target.value);
    if (newAttendance !== attendance) {
      setAttendance(newAttendance);
      setAttendanceCurrent(newAttendance);
    }
  }

  const cancelHandler = () => {
    setShowAttendanceModal(false);
    setAttendance(attendanceCurrent);
  }

  return (
    <Modal
      className="attendance-modal"
      title={formatMessage({
        id: "details.info.attendance",
        defaultMessage: "Attendance",
      })}
      onClose={cancelHandler}
    >
      <Input
        id="attendance"
        type="text"
        name="attendance"
        bsSize="xsmall"
        value={attendance}
        onChange={changeHandler}
      />
      <div className="attendance-modal__button-row">
        <Button variant="danger" onClick={cancelHandler} >
          {formatMessage({ id: "cancel", defaultMessage: "Cancel" })}
        </Button>
        <Button variant="success" onClick={confirmHandler}>Confirm</Button>
      </div>
    </Modal>
  );
}

export default AttendanceModal;
