export const StealPlayerSelect = {
  name: "steal-player-select",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "steal.select-player",
  showOn: [{ state: "primary", value: "steal-player-select", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "steal-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: false,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "steal", type: "eventType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "no-steal-button",
      component: "TypeButton",
      layout: "match-button center",
      mode: "enable",
      text: "steal.none",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      actionGroups: [
        {
          actions: [
            { action: "nextState", value: null, type: "primary" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
