import { useContext, useEffect, useState } from "react";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";

export const useMultiDeviceClockState = (props, clockRunning) => {
  const { currentState, eventStore } = props;
  const { period } = currentState;
  const { fixtureProfile } = useContext(FixtureDetailsContext);
  const [clockState, setClockState] = useState("waitingForOnPitch");

  useEffect(() => {
    const getLastFixtureEvent = () => {
      const events = eventStore.filter((obj) => obj.eventType === "fixture");
      return events[events.length - 1];
    };

    const isPeriodUndefined = () => {
      return !period || period.periodId === undefined || period.periodId === null || !period.periodStatus;
    };

    const lastFixtureSubType = getLastFixtureEvent()?.subType;

    const isPeriodInProgress = period && period.periodStatus === "inProgress";
    const isFixtureNeverStarted =
      !clockRunning &&
      (isPeriodUndefined() ||
        (period.periodId === 1 &&
          (period.periodStatus === "pending" ||
            period.periodStatus === "warm_up" ||
            period.periodStatus === "initial")));
    const needOnPitchEvent = eventStore.every((event) => event.subType !== "on_pitch");
    const needWarmUpEvent = eventStore.every((event) => event.subType !== "warm_up");
    const needThrowOffEvent = eventStore.every((event) => event.eventType !== "throwOff");
    const needAboutToStartEvent = eventStore.every((event) => event.subType !== "about_to_start");
    const isWaitingForOnPitch = isFixtureNeverStarted && needOnPitchEvent;
    const isWaitingForWarmUp = isFixtureNeverStarted && lastFixtureSubType === "on_pitch" && needWarmUpEvent;
    const isWaitingForEndWarmUp =
      isFixtureNeverStarted && needThrowOffEvent && lastFixtureSubType === "warm_up" && needAboutToStartEvent;
    const isWaitingForThrowOff =
      isFixtureNeverStarted &&
      needThrowOffEvent &&
      !needOnPitchEvent &&
      lastFixtureSubType !== "on_pitch" &&
      !needWarmUpEvent &&
      !needAboutToStartEvent;
    const isWaitingForStartMatch = isFixtureNeverStarted && !needThrowOffEvent;
    const isPeriodEnded = !clockRunning && period && period.periodStatus === "ended";
    const isPeriodConfirmPending = !clockRunning && period && period.periodStatus === "periodEnd";
    const isPeriodPending = !clockRunning && period && period.periodId > 1 && period.periodStatus === "pending";
    const isExtraPeriodPending = !clockRunning && period && period.periodStatus === "pendingExtra";
    const isFixtureEndPending = !clockRunning && period && period.periodStatus === "fixtureConfirm";
    const isFixtureComplete = !clockRunning && period && period.periodStatus === "fixtureComplete";
    const isShootOutInProgress = period && period.periodId === fixtureProfile.shootOutPeriodId;

    if (isPeriodInProgress) {
      if (isShootOutInProgress) {
        setClockState("shootOutInProgress");
      } else {
        setClockState(clockRunning ? "running" : "stopped");
      }
    } else if (isWaitingForOnPitch) {
      setClockState("waitingForOnPitch");
    } else if (isWaitingForWarmUp) {
      setClockState("waitingForWarmUp");
    } else if (isWaitingForEndWarmUp) {
      setClockState("waitingForEndWarmUp");
    } else if (isWaitingForThrowOff) {
      setClockState("waitingForThrowOff");
    } else if (isWaitingForStartMatch) {
      setClockState("waitingForStartMatch");
    } else if (isPeriodEnded) {
      setClockState("waitingForPeriodEnd");
    } else if (isPeriodConfirmPending) {
      setClockState("waitingForPeriodConfirm");
    } else if (isPeriodPending) {
      setClockState("waitingForPeriodStart");
    } else if (isExtraPeriodPending) {
      setClockState("waitingForExtraPeriodStart");
    } else if (isFixtureEndPending) {
      setClockState("waitingForFixtureEnd");
    } else if (isFixtureComplete) {
      setClockState("fixtureComplete");
    } else {
      setClockState("undefined");
    }
  }, [clockRunning, JSON.stringify(eventStore), currentState.period, currentState.onPitchEnd]);

  return [clockState];
};
