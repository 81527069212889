export const ENVIRONMENT = import.meta.env.ENVIRONMENT;
export const APP_API_URL = import.meta.env.APP_API_URL;
export const REPORTS_APP_URL = import.meta.env.REPORTS_APP_URL;
export const ADMIN_APP_URL = import.meta.env.ADMIN_APP_URL;
export const FRESHCHAT_TOKEN = import.meta.env.FRESHCHAT_TOKEN;
export const FRESHCHAT_HOST = import.meta.env.FRESHCHAT_HOST;
export const FRESHCHAT_WIDGET_UUID = import.meta.env.FRESHCHAT_WIDGET_UUID;
export const SMP_API_URL = import.meta.env.SMP_API_URL;
export const SMP_CLIENT_ID = import.meta.env.SMP_CLIENT_ID;
export const SIMPLE_BASKETBALL_ORG = import.meta.env.SIMPLE_BASKETBALL_ORG;
