export function checkGameStarted(props) {
  let status = false;
  if (props.currentState && props.currentState.period) {
    switch (props.currentState.period.periodId) {
      case 1:
        status = !["pending", "initial", "warm_up"].includes(props.currentState.period.periodStatus);
        break;
      default:
        status = true;
        break;
    }
  }
  return status;
}

export function generatePeriodTitle(sport, periodId, fixtureProfile, formatMessage, isFull = true) {
  let title = ""

  if (periodId === "All") {
    title = formatMessage({
      id: "filter.all",
      defaultMessage: "All",
    });
  }

  if (periodId <= fixtureProfile.numberOfPeriods) {
    title =  formatMessage(
      {
        id: isFull ? "period.full" : "period.abbreviation",
        defaultMessage: isFull ? "Period {period}" : "P{period}",
      },
      { period: periodId }
    );
  }

  if (periodId >= fixtureProfile.initialExtraPeriodId) {
    title = formatMessage(
      {
        id: isFull ? "period.overtime.full" : "period.overtime.abbreviation",
        defaultMessage: isFull ? "Overtime {period}" : "OT{period}",
      },
      { period: periodId - fixtureProfile.initialExtraPeriodId + 1 }
    );
  }

  if (["handball", "hockey"].includes(sport) && periodId >= fixtureProfile.shootOutPeriodId) {
    title = formatMessage({
      id: isFull ? "period.overtime.shootout" : "period.overtime.shootout.abbreviation",
      defaultMessage: isFull ? "Shootout" : "SO",
    });
  }

  return title;
}
