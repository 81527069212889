const createSuspensionClockEvent = () => [
  { action: "newEventId", value: null, type: "main" },
  {
    action: "saveData",
    value: "clock",
    type: "class",
  },
  {
    action: "saveData",
    value: "suspension",
    type: "eventType",
  },
  {
    action: "saveData",
    value: "adjust",
    type: "subType",
  },
  {
    action: "saveData",
    value: [{ option: "value", value: "2" }],
    type: "options",
  },
  { action: "sendEvent", value: "new", type: "main" },
  { action: "copyEvent", value: "card-clock", type: "to" },
];

export const VideoReviewButton = {
  name: "video-review-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "visible",
  text: "videoReview",
  showOn: [
    {
      state: "videoReview.start",
      value: false,
      operator: "is",
    },
  ],
  startEvent: true,
  startPlay: true,
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        { action: "clearData", value: ["entityId"], type: "main" },
        { action: "saveData", value: "videoReview", type: "eventType" },
        { action: "saveData", value: "start", type: "subType" },
        { action: "sendEvent", value: "new", type: "main" },

        { action: "newEventId", value: null, type: "main" },
        { action: "clearData", value: ["options", "subType"], type: "main" },
        { action: "saveData", value: "betStop", type: "eventType" },
        { action: "saveData", value: "internal", type: "class" },
        { action: "sendEvent", value: "new", type: "main" },

        { action: "saveData", value: "sport", type: "class" },

        { action: "nextState", value: null, type: "primary" },

        { action: "processInserts", value: "all", type: "inserts" },
      ],
    },
  ],
};

export const VideoReviewEndButton = {
  name: "video-review-end-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "visible",
  text: "videoReview.end",
  showOn: [
    {
      state: "videoReview.start",
      value: true,
      operator: "is",
    },
  ],
  startEvent: true,
  startPlay: true,
  actionGroups: [
    {
      actions: [
        { action: "clearData", value: ["entityId"], type: "main" },
        { action: "saveData", value: "videoReview", type: "eventType" },
        { action: "saveData", value: "end", type: "subType" },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: null, type: "primary" },

        { action: "processInserts", value: "all", type: "inserts" },
      ],
    },
  ],
};

export const SwitchTeamsButton = {
  name: "switch-teams",
  component: "TeamSwitch",
  layout: "match-button small",
  mode: "enable",
  sendUpdateArrow: false,
  showOn: [{ state: "clock.clockRunning", value: false, operator: "is" }],
  startEvent: false,
  startPlay: false,
};

export const TimeoutButton = {
  name: "timeout-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "timeout",
  showForInsert: true,
  showOn: [
    {
      state: "period.periodStatus",
      value: "inProgress",
      operator: "is",
    },
  ],
  startEvent: true,
  startPlay: true,
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        {
          action: "nextState",
          value: "timeout",
          type: "primary",
        },
      ],
      conditions: [],
    },
  ],
};

export const Penalty7MeterButton = {
  name: "penalty-7meter-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "penalty.7meter",
  showForInsert: true,
  showOn: [
    { state: "period.periodStatus", operator: "not", value: "fixtureConfirm" },
    { state: "period.periodStatus", operator: "not", value: "fixtureComplete" },
  ],
  startEvent: true,
  startPlay: true,
  actionGroups: [
    {
      actions: [
        { action: "clearData", value: ["options", "success"], type: "main" },
        { action: "saveData", value: "sevenMetrePenalty", type: "eventType" },
        { action: "saveData", value: "awarded", type: "subType" },
        {
          action: "saveData",
          value: [{ option: "possible", value: true }],
          type: "options",
        },
        { action: "sendEvent", value: "new", type: "main" },
        {
          action: "nextState",
          value: "penalty-foul-drawn-player",
          type: "primary",
        },
      ],
      conditions: [],
    },
  ],
};

export const SanctionsButton = {
  name: "sanctions-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "sanctions",
  showForInsert: true,
  showOn: [
    { state: "period.periodStatus", operator: "not", value: "fixtureConfirm" },
    { state: "period.periodStatus", operator: "not", value: "fixtureComplete" },
  ],
  actionGroups: [
    {
      actions: [{ action: "nextState", value: "sanctions-type", type: "primary" }],
      conditions: [],
    },
  ],
};

export const PassivePlayButton = {
  name: "passive-play-button",
  component: "TypeButton",
  layout: "match-button wide team passive-play-button",
  mode: "visible",
  text: "passivePlay",
  showOn: [true],
  actionGroups: [
    {
      actions: [
        { action: "saveData", value: "passivePlay", type: "eventType" },
        { action: "sendEvent", value: "new", type: "main" },
        {
          action: "setPossession",
          value: { currentEntity: false },
          type: "main",
        },
        { action: "nextState", value: null, type: "primary" },
        { action: "processInserts", value: "all", type: "inserts" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const RedCardButton = {
  name: "red-card",
  component: "TypeButton",
  layout: "type-button redCard",
  mode: "enable",
  text: "redCard",
  showOn: [true],
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        { action: "saveData", value: "redCard", type: "eventType" },
        {
          action: "saveData",
          value: [{ option: "possible", value: true }],
          type: "options",
        },
        { action: "sendEvent", value: "new", type: "main" },

        { action: "copyEvent", value: "card", type: "to" },

        { action: "newEvent", value: null, type: "main" },
        { action: "saveData", value: "suspension", type: "eventType" },
        { action: "saveData", value: "twoMinutes", type: "subType" },
        {
          action: "saveData",
          value: [{ option: "possible", value: true }],
          type: "options",
        },
        { action: "sendEvent", value: "new", type: "main" },

        { action: "copyEvent", value: "suspension", type: "to" },

        { action: "clearData", value: ["options"], type: "main" },

        ...createSuspensionClockEvent(),
        { action: "copyEvent", value: "card", type: "from" },
        {
          action: "nextState",
          value: "punishment-player",
          type: "primary",
        },

        { action: "processInserts", value: "all", type: "inserts" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const YellowCardButton = {
  name: "yellow-card",
  component: "TypeButton",
  layout: "type-button yellowCard",
  mode: "enable",
  text: "yellowCard",
  showOn: [true],
  actionGroups: [
    {
      actions: [
        { action: "saveData", value: "yellowCard", type: "eventType" },
        {
          action: "saveData",
          value: [{ option: "possible", value: true }],
          type: "options",
        },
        { action: "sendEvent", value: "new", type: "main" },
        {
          action: "nextState",
          value: "punishment-player",
          type: "primary",
        },

        { action: "processInserts", value: "all", type: "inserts" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const BlueCardButton = {
  name: "blue-card",
  component: "TypeButton",
  layout: "type-button blueCard",
  mode: "enable",
  text: "blueCard",
  showOn: [true],
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        { action: "saveData", value: "blueCard", type: "eventType" },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "copyEvent", value: "card", type: "to" },
        {
          action: "nextState",
          value: "punishment-player",
          type: "primary",
        },

        { action: "processInserts", value: "all", type: "inserts" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const SuspensionButton = {
  name: "suspension-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "suspension",
  showForInsert: true,
  showOn: [
    { state: "period.periodStatus", operator: "not", value: "fixtureConfirm" },
    { state: "period.periodStatus", operator: "not", value: "fixtureComplete" },
  ],
  actionGroups: [
    {
      actions: [{ action: "nextState", value: "suspension-type", type: "primary" }],
      conditions: [],
    },
  ],
};
