export const PlayerFoulTypes = [
  "personal",
  "shooting",
  "offensive",
  "technical",
  "unsportsmanlike",
  "disqualifying",
  "throwIn",
  "double",
  "cancellation",
];

export const BenchFoulTypes = ["benchTechnical", "cancellation"];

export const CoachFoulTypes = ["coachTechnical", "coachDisqualifying", "cancellation"];

export const TechnicalFoulTypes = ["technical", "benchTechnical", "coachTechnical"];

export const CancellationPlayerFoulTypes = [
  "technical",
  "throwIn",
  // commenting out temporarily until we figure out how to handle foul drawn in this flow better
  // "unsportsmanlike",
  // "disqualifying",
];

export const CancellationCoachFoulTypes = ["coachTechnical", "coachDisqualifying"];

export const CancellationBenchFoulTypes = ["benchTechnical"];

export const CancellationDisqualifyingFoulTypes = ["unsportsmanlike", "disqualifying", "coachDisqualifying"];

export const DoubleFoulTypes = ["personal", "unsportsmanlike", "disqualifying"];
