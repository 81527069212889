export const ShotPlayerSelect = {
  name: "shot-player",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "shot.select-player",
  showOn: [{ state: "primary", value: "shot-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "shot-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      options: { currentEntity: true, showBench: false, showCoach: false },
      actionGroups: [
        {
          actions: [{ action: "sendEvent", value: "edit", type: "main" }],
          conditions: [],
        },
        {
          actions: [{ action: "nextState", value: "shot-type", type: "primary" }],
          conditions: [
            {
              item: "eventType",
              value: "2pt",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "setPossession", value: { currentEntity: false }, type: "main" },
            { action: "copyEvent", value: "shot", type: "to" },
            { action: "nextState", value: "assist-player", type: "primary" },
          ],
          conditions: [
            {
              item: "eventType",
              value: "3pt",
              event: "main",
              operator: "is",
            },
            { item: "success", value: true, event: "main", operator: "is" },
          ],
          operator: "all",
        },
        {
          actions: [
            { action: "copyEvent", value: "shot", type: "to" },
            { action: "nextState", value: "shot-rebound", type: "primary" },
          ],
          conditions: [
            {
              item: "eventType",
              value: "3pt",
              event: "main",
              operator: "is",
            },
            { item: "success", value: true, event: "main", operator: "not" },
          ],
          operator: "all",
        },
      ],
    },
  ],
};
