export const ModifySimpleTimeout = {
  name: "modify-simple-timeout",
  component: "MainPanel",
  layout: "main-panel large padded align-center",
  mode: "visible",
  text: "timeout.type",
  allowFlag: true,
  allowCancel: true,
  showOn: [{ state: "editState", value: "timeout-type", operator: "is" }],
  children: [
    {
      name: "team-0-timeout",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 0 },
      actionGroups: [],
    },
    {
      name: "official-timeout",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: "official",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "officials", type: "subType" },
            { action: "clearData", value: ["entityId"], type: "main" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "team-1-timeout",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 1 },
      actionGroups: [],
    },
    {
      name: "ok-button",
      component: "TypeButton",
      layout: "match-button center action",
      mode: "enable",
      text: "ok",
      startEvent: false,
      startPlay: false,
      showOn: [
        {
          state: "event.subType",
          value: null,
          operator: "not",
          event: "main",
        },
      ],
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
