const commonFoulDrawnActions = [
  { action: "saveData", value: "foul", type: "eventType" },
  { action: "saveData", value: "drawn", type: "subType" },
  {
    action: "clearData",
    value: ["options"],
    type: "main",
  },
  { action: "sendEvent", value: "new", type: "main" },
  { action: "copyEvent", value: "fouldrawn", type: "to" },
];

export const FoulDrawnPlayerSelect = {
  name: "foul-drawn-player-select",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "fouldrawn.select-player",
  showOn: [{ state: "primary", value: "foul-drawn-player-select", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "foul-drawn-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: false,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            ...commonFoulDrawnActions,
            { action: "copyEvent", value: "foul", type: "from" },
            { action: "nextState", value: "free-throw-awarded", type: "primary" },
          ],
          conditions: [
            {
              item: "options",
              value: "shooting",
              event: "foul",
              operator: "not",
            },
          ],
        },
        {
          actions: [
            ...commonFoulDrawnActions,
            { action: "copyEvent", value: "foul", type: "from" },
            { action: "nextState", value: "free-throws-select", type: "primary" },
          ],
          conditions: [
            {
              item: "options",
              value: "shooting",
              event: "foul",
              operator: "is",
            },
          ],
        },
      ],
    },
    {
      name: "no-fouldrawn",
      component: "TypeButton",
      layout: "match-button center",
      mode: "enable",
      text: "foul.drawn.none",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            { action: "clearEvent", value: "fouldrawn", type: "main" },
            { action: "copyEvent", value: "foul", type: "from" },
            { action: "nextState", value: "free-throw-awarded", type: "primary" },
          ],
          conditions: [
            {
              item: "options",
              value: "shooting",
              event: "foul",
              operator: "not",
            },
          ],
        },
        {
          actions: [
            { action: "clearEvent", value: "fouldrawn", type: "main" },
            { action: "copyEvent", value: "foul", type: "from" },
            { action: "nextState", value: "free-throws-select", type: "primary" },
          ],
          conditions: [
            {
              item: "options",
              value: "shooting",
              event: "foul",
              operator: "is",
            },
          ],
        },
      ],
    },
  ],
};
