const commonShotTypeActionGroups = [
  {
    actions: [
      { action: "clearData", value: ["subType"], type: "main" },
      { action: "saveData", type: "flagged", value: true },
    ],
    conditions: [{ item: "subType", value: "confirmLater", event: "main", operator: "is" }],
  },
  {
    actions: [
      { action: "copyEvent", value: "shot", type: "to" },
      { action: "sendEvent", value: "edit", type: "main" },
    ],
    conditions: [],
  },
  {
    actions: [
      { action: "setPossession", value: { currentEntity: false }, type: "main" },
      { action: "nextState", value: "assist-player", type: "primary" },
    ],
    conditions: [{ item: "success", value: true, event: "main", operator: "is" }],
  },
  {
    actions: [{ action: "nextState", value: "shot-rebound", type: "primary" }],
    conditions: [{ item: "success", value: true, event: "main", operator: "not" }],
    operator: "all",
  },
];

export const ShotTypeSelect = {
  name: "shot-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "shot.type",
  allowBack: { state: "primary", value: "shot-player", event: "main" },
  showOn: [{ state: "primary", value: "shot-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "2pt-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.eventType",
          value: "2pt",
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "subType",
        children: ["jumpShot", "layup", "dunk", "confirmLater"],
        layout: "type-button",
      },
      actionGroups: [...commonShotTypeActionGroups],
    },
    {
      name: "3pt-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.eventType",
          value: "3pt",
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "subType",
        children: ["jumpShot", "confirmLater"],
        layout: "type-button",
      },
      actionGroups: [...commonShotTypeActionGroups],
    },
  ],
};
