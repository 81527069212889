import React, { useContext } from "react";
import { injectIntl } from "react-intl";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";
import { formatPlayerShortenedName } from "../../../Base/Utilities/Player";
import { generatePeriodTitle } from "../../../Base/Utilities/Game.js";
import { SIMPLE_BASKETBALL_ORG } from "../../../../Config/Environment.js";

const SHARED_FILTERS = {
  flagged: "flagged",
  unsent: "unsent",
};
const EMPTY_VALUE = -1;

const RESET_EVENT = { target: { value: EMPTY_VALUE } };

function getEventTypesBySport(sport, organizationId) {
  if (sport === "hockey") {
    return {
      ...SHARED_FILTERS,
      scores: "goals",
      fouls: "cards",
    };
  }

  if (sport === "handball") {
    return {
      ...SHARED_FILTERS,
      scores: "goals",
      sevenMetrePenalty: "sevenMetrePenalty",
      substitution: "substitution",
      sanctions: "sanctions",
    };
  }

  if (sport === "basketball") {
    const grassrootsOrgIds = SIMPLE_BASKETBALL_ORG.split(",")
    if (grassrootsOrgIds.includes(organizationId)) {
      return {
        ...SHARED_FILTERS,
        assist: "assist",
        block: "block",
        foulBy: "foulBy",
        foulDrawn: "foulDrawn",
        fouls: "fouls",
        freeThrow: "freeThrow",
        rebound: "rebound",
        scores: "scores",
        substitution: "substitution",
        timeOut: "timeOut",
        turnover: "turnover",
      };
    }
    return {
      ...SHARED_FILTERS,
      assist: "assist",
      block: "block",
      fastBreak: "fastBreak",
      foulBy: "foulBy",
      foulDrawn: "foulDrawn",
      fouls: "fouls",
      freeThrow: "freeThrow",
      rebound: "rebound",
      scores: "scores",
      steal: "steal",
      substitution: "substitution",
      timeOut: "timeOut",
      turnover: "turnover",
    };
  }

  return {
    ...SHARED_FILTERS,
    scores: "scores",
    fouls: "fouls",
  };
}

const EventLogFilter = (props) => {
  const { periods, intl, clearFilters, filterValues, filterActive, setFilter, personList } = props;
  const { formatMessage } = intl;
  const { fixtureProfile, sport, organization: organizationConfig } = useContext(FixtureDetailsContext);
  const organizationId = organizationConfig?.organizationId;
  const filterOptions = getEventTypesBySport(sport, organizationId);

  const onFilterChange = (ev) => {
    const { value } = ev.target;

    if (value === SHARED_FILTERS.flagged) {
      setFilter("eventType", RESET_EVENT);
      setFilter("flagged", ev);
    } else if (value === EMPTY_VALUE) {
      setFilter("eventType", RESET_EVENT);
      setFilter("flagged", RESET_EVENT);
    } else {
      setFilter("flagged", RESET_EVENT);
      setFilter("eventType", ev);
    }
  };

  return (
    <div className={"event-log-filters filtered-" + filterActive}>
      {filterActive && (
        <span className="clear-filters" onClick={() => clearFilters()}>
          <i className="fas fa-filter"></i>
          <i className="fas fa-times"></i>
          {/* {formatMessage({
            id: "clear",
            defaultMessage: "Clear",
          })} */}
        </span>
      )}
      <select className="period-filter" onChange={(e) => setFilter("period", e)} value={filterValues.period}>
        {periods.map((period) => {
          return (
            <option value={period} key={period}>
              {generatePeriodTitle(sport, period, fixtureProfile, formatMessage, false)}
            </option>
          );
        })}
        {sport === "handball" && (
          <option value="All">
            {formatMessage({
              id: "filter.all",
              defaultMessage: "All",
            })}
          </option>
        )}
      </select>
      <select
        className="type-filter"
        onChange={(e) => onFilterChange(e)}
        value={filterValues.eventType === EMPTY_VALUE ? filterValues.flagged : filterValues.eventType}
      >
        <option value={EMPTY_VALUE}>
          {formatMessage({
            id: "filter.all",
            defaultMessage: "All",
          })}
        </option>
        {Object.keys(filterOptions).map((type, idx) => {
          return (
            <option value={type} key={`${type}-${idx}`}>
              {formatMessage({
                id: "filter." + filterOptions[type],
                defaultMessage: type,
              })}
            </option>
          );
        })}
      </select>
      <select className="person-filter" onChange={(e) => setFilter("personId", e)} value={filterValues.personId}>
        <option value={parseInt(-1)} key={-1}>
          {formatMessage({
            id: "filter.all",
            defaultMessage: "All",
          })}
        </option>
        {personList.map((person, idx) => {
          return (
            <option value={person.personId} key={`${person.personId}-${idx}`}>
              {person.bib}. {formatPlayerShortenedName(person.name)}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default injectIntl(EventLogFilter);
