export function toCatchupFilter(events, currentState) {
  if (!currentState.catchupDoneEvents) {
    return events;
  }

  return events.filter((msg) => {
    const catchupDoneTimestamp = currentState.catchupDoneEvents[msg.eventId];
    if (!catchupDoneTimestamp) {
      return true;
    }

    return new Date(catchupDoneTimestamp.replace("Z", "")) < new Date(msg.timestamp.replace("Z", ""));
  });
}
