export const Clock = {
  name: "clock",
  component: "MultiDeviceClock",
  layout: "mdclock-root",
  mode: "visible",
  options: { disablePreMatch: false },
  showOn: [{ state: "clock.displayTime", value: null, operator: "not" }],
  actionGroups: [
    {
      actions: [
        {
          action: "setPossession",
          value: { currentEntity: true },
          type: "main",
        },
      ],
      conditions: [
        {
          item: "clock.clockStatus",
          value: "periodStart",
          event: "main",
          operator: "is",
        },
        {
          item: "period",
          value: 2,
          event: "periodId",
          operator: "not",
        },
      ],
    },
    {
      actions: [
        { action: "newEvent", value: null, type: "main" },
        { action: "saveData", value: { hasPossession: true }, type: "entityId" },
        {
          action: "setPossession",
          value: { currentEntity: false },
          type: "main",
        },
      ],
      conditions: [
        {
          item: "throwOffTeam",
          operator: "is",
          value: true,
        },
        {
          item: "period",
          value: 2,
          event: "periodId",
          operator: "is",
        },
        {
          item: "clock.clockStatus",
          value: "waitingForPeriodConfirm",
          event: "main",
          operator: "is",
        },
      ],
    },
    {
      actions: [
        {
          action: "checkResets",
          value: true,
          type: "clock",
        },
      ],
      conditions: [
        {
          item: "period.periodStatus",
          value: "pending",
          event: "periodStatus",
          operator: "is",
        },
      ],
    },
  ],
};
