export var InsertFoul = {
  name: "insert-foul",
  component: "MainPanel",
  layout: "main-panel large center no-group",
  mode: "visible",
  text: "foul.add",
  showOn: [{ state: "editState", value: "child-insert-foul", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit-0", "edit-1"] },
  children: [
    {
      name: "foul-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: false,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "foul", type: "eventType" },
            {
              action: "sendEvent",
              value: "new",
              type: "main",
            },
            { action: "copyEvent", value: "foul", type: "to" },
            { action: "copyEvent", value: "edit-0", type: "from" },
            {
              action: "clearData",
              value: ["options", "success"],
            },
            { action: "saveData", value: "foul", type: "eventType" },
            { action: "saveData", value: "drawn", type: "subType" },
            { action: "copyEvent", value: "fouldrawn", type: "to" },
            { action: "copyEvent", value: "foul", type: "from" },
            { action: "nextState", value: "child-insert-foul-type", type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};

export var InsertFoulType = {
  name: "insert-foul-type",
  component: "MainPanel",
  layout: "main-panel large center no-group",
  mode: "visible",
  text: "foul.type",
  showOn: [{ state: "editState", value: "child-insert-foul-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit-0", "edit-1"] },
  children: [
    {
      name: "foul-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: ["personal", "unsportsmanlike"],
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            { action: "copyEvent", value: "foul", type: "to" },
            { action: "nextState", value: "child-insert-freethrows", type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
