import React, { useEffect, useMemo } from "react";
import { injectIntl } from "react-intl";

import PlayerSelect from "../PlayerSelect/PlayerSelect";
import ButtonPanel from "../ButtonPanel/ButtonPanel";
import ButtonGroup from "../ButtonPanel/ButtonGroup/ButtonGroup";
import OptionGroup from "../ButtonPanel/OptionGroup/OptionGroup";
import TypeButton from "../ButtonPanel/TypeButton/TypeButton";
import SubPanel from "./SubPanel/SubPanel";
import PanelText from "./PanelText/PanelText";
import Freethrows from "../ScoreButtons/Basketball/Freethrows/Freethrows";
import Jumpball from "../StartMatch/Basketball/Jumpball/Jumpball";
import PreAction from "./PreAction/PreAction";
import MiniLocation from "../PositionSelect/MiniLocation";
import Substitutions from "../Players/basketball/Substitutions/Substitutions";
import FaceOff from "../FaceOff/FaceOff";
import Shootout from "../Shootout/Shootout";
import ShootoutClockController from "../ShootoutClockController/ShootoutClockController";
import ThrowOff from "../ThrowOff/ThrowOff";
import WarmupClock from "../WarmupClock/WarmupClock";
import LocationEditor from "../LocationEditor/LocationEditor";
import SimpleFreeThrows from "../ScoreButtons/Basketball/Freethrows/SimpleFreeThrows";

import CoinToss from "../CoinToss/CoinToss";
import ChangeGoalkeeper from "../ChangeGoalkeeper/ChangeGoalkeeper";

import EventDetails from "./EventDetails/EventDetails";
import Bookmark from "./Bookmark/Bookmark";
import Cancel from "./Cancel/Cancel";
import GoBack from "./GoBack/GoBack";

import { useIsVisible } from "../../Base/Hooks/IsVisible";

import "./MainPanel.scss";

const MainPanel = (props) => {
  const { panel, intl } = props;
  const { formatMessage } = intl;
  const [isVisible, isEnabled] = useIsVisible(panel, props);

  useEffect(() => {
    if (isVisible) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isVisible]);

  useEffect(() => () => document.querySelector("body").classList.remove("overflow-hidden"), []);

  const layout = useMemo(() => {
    if (panel && panel.children) {
      return panel.children.map((panel, index) => (
        <React.Fragment key={index}>
          {(function () {
            switch (panel.component) {
              case "PlayerSelect":
                return <PlayerSelect {...props} panel={panel} />;
              case "ButtonPanel":
                return <ButtonPanel {...props} panel={panel} />;
              case "ButtonGroup":
                return <ButtonGroup {...props} panel={panel} />;
              case "OptionGroup":
                return <OptionGroup {...props} panel={panel} />;
              case "TypeButton":
                return <TypeButton {...props} panel={panel} />;
              case "SubPanel":
                return <SubPanel {...props} panel={panel} />;
              case "Freethrows":
                return <Freethrows {...props} panel={panel} />;
              case "Jumpball":
                return <Jumpball {...props} panel={panel} />;
              case "PanelText":
                return <PanelText {...props} panel={panel} />;
              case "MiniLocation":
                return <MiniLocation {...props} panel={panel} />;
              case "PreAction":
                return <PreAction {...props} panel={panel} />;
              case "Substitutions":
                return <Substitutions {...props} panel={panel} />;
              case "FaceOff":
                return <FaceOff {...props} panel={panel} />;
              case "ShootOut":
                return <Shootout {...props} panel={panel} />;
              case "ShootoutClockController":
                return <ShootoutClockController {...props} panel={panel} />;
              case "CoinToss":
                return <CoinToss {...props} panel={panel} />;
              case "ThrowOff":
                return <ThrowOff {...props} panel={panel} />;
              case "ChangeGoalkeeper":
                return <ChangeGoalkeeper {...props} panel={panel} />;
              case "WarmupClock":
                return <WarmupClock {...props} panel={panel} />;
              case "LocationEditor":
                return <LocationEditor {...props} panel={panel} />;
              case "SimpleFreeThrows":
                return <SimpleFreeThrows {...props} panel={panel} />;
              default:
                return null;
            }
          })()}
        </React.Fragment>
      ));
    }
    // eslint-disable-next-line
  }, [panel, props]);

  return (
    <React.Fragment>
      {isVisible && (
        <div className={panel.layout + " enabled-" + isEnabled}>
          <div>
            {panel.text && (
              <h3>
                {formatMessage({
                  id: panel.text,
                  defaultMessage: panel.text,
                })}
                :
                <div className="panel-extras">
                  {panel.allowFlag && <Bookmark {...props} />}
                  {panel.allowCancel && <Cancel {...props} panel={panel} events={props.currentState.events} />}
                  {panel.allowBack && panel.allowBack.value && <GoBack {...props} />}
                </div>
              </h3>
            )}
            {panel.showDetails && (
              <div className="event-details">
                {panel.showDetails.events &&
                  panel.showDetails.events.map((event, index) => <EventDetails {...props} event={event} key={index} />)}
              </div>
            )}
            {layout}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(MainPanel);
