export const FoulTypeSelect = {
  name: "foul-type-select",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "foul.type",
  showOn: [{ state: "primary", value: "foul-type-select", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  allowBack: { state: "primary", value: "foul-player-select", event: "main" },
  children: [
    {
      name: "foul-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: ["personal", "offensive", "technical", "unsportsmanlike"],
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "copyEvent", value: "foul", type: "to" },
            { action: "nextState", value: "foul-drawn-player-select", type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
