import { Clock, EventInsert, InsertClock, SwitchTeamsButton } from "./includes/Base";
import {
  FreeThrowSuccess,
  InsertChildPlayerOtherTeam,
  InsertChildPlayerSameTeam,
  ModifyPlayerSelect,
  ModifySubstitutionsSelect,
} from "./includes/Modify";
import { BlockPlayer, JumpballStartGame, ReboundFollowOn, ReboundPlayer } from "./includes/Panels";
import {
  FoulButton,
  Made2AndOneButton,
  Made2Button,
  Made3AndOneButton,
  Made3Button,
  Missed2Button,
  Missed3Button,
  ShootingFoulButton,
  SimpleJumpBallButton,
  SimpleTimeoutButton,
  TurnoverNoWhistleButton,
  TurnoverWhistleButton,
} from "./includes/simpleBasketball/ActionButtons";
import { And1FoulPlayerSelect } from "./includes/simpleBasketball/And1FoulPlayerSelect";
import { AssistPlayerSelect } from "./includes/simpleBasketball/AssistPlayerSelect";
import { FoulDrawnPlayerSelect } from "./includes/simpleBasketball/FoulDrawnPlayerSelect";
import { FoulPlayerSelect } from "./includes/simpleBasketball/FoulPlayerSelect";
import { FoulTypeSelect } from "./includes/simpleBasketball/FoulTypeSelect";
import { FreeThrowsAwarded } from "./includes/simpleBasketball/FreeThrowsAwarded";
import { FreeThrowsSelect } from "./includes/simpleBasketball/FreeThrowsSelect";
import { FreeThrowsSelectLocked } from "./includes/simpleBasketball/FreeThrowsSelectLocked";
import { ModifyShotTypeSelect } from "./includes/simpleBasketball/ModifyShotType";
import { ModifySimpleTimeout } from "./includes/simpleBasketball/ModifySimpleTimeout";
import { ShotPlayerSelect } from "./includes/simpleBasketball/ShotPlayerSelect";
import { ShotTypeSelect } from "./includes/simpleBasketball/ShotTypeSelect";
import { SimpleTimeout } from "./includes/simpleBasketball/SimpleTimeout";
import { StealPlayerSelect } from "./includes/simpleBasketball/StealPlayerSelect";
import { TurnoverPlayerSelect } from "./includes/simpleBasketball/TurnoverPlayerSelect";

const SimpleBasketballWorkflow = {
  name: "simple",
  sport: "basketball",
  managePossession: true,
  manageAlternatingArrow: true,
  displayEventLog: true,
  defaultLocations: [
    { x: 5, y: 50 },
    { x: 95, y: 50 },
  ],
  panels: [
    {
      name: "main-area",
      component: "StackingPanel",
      showOn: [true],
      layout: "column",
      children: [
        {
          name: "top-panel",
          component: "StackingPanel",
          layout: "fill-content",
          showOn: [true],
          children: [
            {
              name: "team-0-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              possessionIcon: "basketball-ball",
              showPossessionIcon: true,
              showArrow: true,
              showStats: true,
            },
            {
              name: "clock-and-buttons",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fill-parent clock-and-buttons",
              children: [
                Clock,
                {
                  name: "match-buttons",
                  component: "ButtonPanel",
                  layout: "match-buttons-stackable",
                  mode: "visible",
                  showOn: [true],
                  children: [SimpleTimeoutButton, SwitchTeamsButton, SimpleJumpBallButton],
                },
              ],
            },
            {
              name: "team-1-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              possessionIcon: "basketball-ball",
              showPossessionIcon: true,
              showArrow: true,
              showStats: true,
            },
          ],
        },
        {
          name: "play-area",
          component: "StackingPanel",
          showOn: [true],
          layout: "fill-parent",
          children: [
            {
              name: "players-0",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fit-content court-margin",
              children: [
                {
                  name: "team-0-player-select",
                  component: "Players",
                  showOn: [true],
                  mode: "visible",
                },
              ],
            },
            {
              name: "middle-play-area",
              component: "StackingPanel",
              showOn: [true],
              layout: "row fill-content gapped",
              children: [
                {
                  name: "middle-play-area",
                  component: "StackingPanel",
                  showOn: [true],
                  layout: "column half-fill",
                  children: [
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.scoring",
                      options: { team: 0 },
                      layout: "row full twoInRow",
                      children: [Made2Button, Made3Button, Made2AndOneButton, Made3AndOneButton],
                    },
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.missed",
                      options: { team: 0 },
                      layout: "row full twoInRow",
                      children: [Missed2Button, Missed3Button],
                    },
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.foul",
                      options: { team: 0 },
                      layout: "row full twoInRow",
                      children: [FoulButton, ShootingFoulButton],
                    },
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.other",
                      options: { team: 0 },
                      layout: "row full twoInRow freeHeightButtons",
                      children: [TurnoverWhistleButton, TurnoverNoWhistleButton],
                    },
                  ],
                },
                {
                  name: "middle-play-area",
                  component: "StackingPanel",
                  showOn: [true],
                  layout: "column half-fill",
                  children: [
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.scoring",
                      options: { team: 1 },
                      layout: "row full twoInRow",
                      children: [Made2Button, Made3Button, Made2AndOneButton, Made3AndOneButton],
                    },
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.missed",
                      options: { team: 1 },
                      layout: "row full twoInRow",
                      children: [Missed2Button, Missed3Button],
                    },
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.foul",
                      options: { team: 1 },
                      layout: "row full twoInRow",
                      children: [FoulButton, ShootingFoulButton],
                    },
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.other",
                      options: { team: 1 },
                      layout: "row full twoInRow freeHeightButtons",
                      children: [TurnoverWhistleButton, TurnoverNoWhistleButton],
                    },
                  ],
                },
              ],
            },
            {
              name: "players-1",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fit-content court-margin",
              children: [
                {
                  name: "team-1-player-select",
                  component: "Players",
                  showOn: [true],
                  mode: "visible",
                },
              ],
            },
          ],
        },
      ],
    },
    ShotPlayerSelect,
    ShotTypeSelect,
    AssistPlayerSelect,
    ReboundPlayer,
    ReboundFollowOn,
    BlockPlayer,
    FoulPlayerSelect,
    FoulDrawnPlayerSelect,
    FoulTypeSelect,
    FreeThrowsAwarded,
    FreeThrowsSelect,
    FreeThrowsSelectLocked,
    And1FoulPlayerSelect,
    SimpleTimeout,
    JumpballStartGame,
    StealPlayerSelect,
    TurnoverPlayerSelect,
    InsertClock,
    EventInsert,
    {
      name: "edit",
      component: "EditPanel",
      layout: "edit-base",
      mode: "visible",
      showOn: true,
    },
    {
      name: "delete",
      component: "DeletePanel",
      layout: "delete-base",
      mode: "visible",
      showOn: true,
    },
    {
      name: "insert",
      component: "InsertPanel",
      layout: "insert-base",
      mode: "visible",
      showOn: true,
    },
    {
      name: "insert-clock",
      component: "InsertClock",
      layout: "insert-clock",
      mode: "visible",
      options: { disablePreMatch: true },
      showOn: [{ state: "insertMode.enabled", value: true, operator: "is" }],
      actionGroups: [],
    },
  ],
  modify: {
    types: [
      {
        name: "shot_made",
        eventTypes: ["2pt", "3pt"],
        success: true,
        editable: [
          { type: "personId", text: "edit.shot.person", panel: "player" },
          { type: "subType", text: "edit.shot.type", panel: "shot-type" },
        ],
        delete: true,
        delete_all: true,
        confirm_possession: true,
        child_inserts: [
          {
            type: "assist",
            text: "insert.child.assist",
            panel: "child-insert-same",
            subType: "",
          },
        ],
      },
      {
        name: "shot_missed",
        eventTypes: ["2pt", "3pt"],
        success: false,
        editable: [
          { type: "personId", text: "edit.shot.person", panel: "player" },
          { type: "subType", text: "edit.shot.type", panel: "shot-type" },
        ],
        delete: true,
        delete_all: true,
        confirm_possession: true,
      },
      {
        name: "assist",
        eventTypes: ["assist"],
        success: null,
        editable: [{ type: "personId", text: "edit.assist.person", panel: "player" }],
        delete: true,
        delete_all: false,
        confirm_possession: false,
      },
      {
        name: "foul",
        eventTypes: ["foul"],
        success: null,
        subType: { subType: "drawn", operator: "not" },
        editable: [{ type: "personId", text: "edit.foul.person", panel: "player" }],
        delete: true,
        delete_all: true,
        confirm_possession: false,
      },
      {
        name: "foul",
        eventTypes: ["foul"],
        subType: { subType: "drawn", operator: "is" },
        success: null,
        editable: [{ type: "personId", text: "edit.fouldrawn.person", panel: "player" }],
        delete: true,
        delete_all: true,

        confirm_possession: false,
      },
      {
        name: "free_throw_made",
        eventTypes: ["freeThrow"],
        success: true,
        editable: [
          { type: "personId", text: "edit.freethrow.person", panel: "player" },
          {
            type: "success",
            text: "edit.freethrow.success.missed",
            panel: "free-throw-success",
          },
        ],
        delete: true,
        delete_all: false,
        confirm_possession: true,
      },
      {
        name: "free_throw_missed",
        eventTypes: ["freeThrow"],
        success: false,
        editable: [
          { type: "personId", text: "edit.freethrow.person", panel: "player" },
          {
            type: "success",
            text: "edit.freethrow.success.made",
            panel: "free-throw-success",
          },
        ],
        delete: true,
        delete_all: false,
        confirm_possession: true,
      },
      {
        name: "substitution",
        eventTypes: ["substitution"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.substitutions",
            panel: "edit-substitutions",
          },
        ],
        delete: false,
        delete_children: [],
        confirm_possession: false,
        skipShowList: {
          panel: "edit-substitutions",
        },
      },
      {
        name: "timeOut",
        eventTypes: ["timeOut"],
        success: null,
        editable: [{ type: "subType", text: "edit.timeout.type", panel: "timeout-type" }],
        delete: true,
        delete_all: true,
        confirm_possession: false,
      },
      {
        name: "turnover",
        eventTypes: ["turnover"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.turnover.person",
            panel: "player",
            conditions: [{ item: "personId", value: null, event: "main", operator: "not" }],
          },
        ],
        delete: true,
        delete_all: true,
        confirm_possession: false,
        child_inserts: [
          {
            type: "steal",
            text: "insert.child.steal",
            panel: "child-insert-other",
            team: false,
          },
        ],
      },
      {
        name: "steal",
        eventTypes: ["steal"],
        success: null,
        editable: [{ type: "personId", text: "edit.steal.person", panel: "player" }],
        delete: true,
        delete_all: false,
        confirm_possession: false,
      },
    ],
    panels: [
      ModifyPlayerSelect,
      ModifyShotTypeSelect,
      ModifySubstitutionsSelect,
      FreeThrowSuccess,
      ModifySimpleTimeout,
      InsertChildPlayerSameTeam,
      InsertChildPlayerOtherTeam,
    ],
  },
};

export default SimpleBasketballWorkflow;
