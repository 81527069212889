export const FoulPlayerSelect = {
  name: "foul-player-select",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "foul.select-player",
  showOn: [{ state: "primary", value: "foul-player-select", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "foul-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: false,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "nextState", value: "foul-type-select", type: "primary" },
          ],
          conditions: [
            {
              item: "options",
              value: "shooting",
              event: "main",
              operator: "not",
            },
          ],
        },
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "copyEvent", value: "foul", type: "to" },
            { action: "nextState", value: "foul-drawn-player-select", type: "primary" },
          ],
          conditions: [
            {
              item: "options",
              value: "shooting",
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
  ],
};
