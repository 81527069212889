import { injectIntl } from "react-intl";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark";
import "./PlayerSelectEditor.scss";

const PlayerSelectEditor = (props) => {
  const {
    team,
    showTeamAssign,
    onPlayerSelect,
    onTeamAssign,
    intl,
    event,
    eventStore,
    sport,
  } = props;
  const { formatMessage } = intl;

  const excludedPlayers = [];
  if (sport === "basketball" && event.eventType === "assist") {
    const playEvents = eventStore.filter(e => e.playId === event.playId);
    playEvents.map(e => {
      if (e.personId !== event.personId) {
        excludedPlayers.push(e.personId);
      }
    });
  }

  return (
    <div className="player-select-editor-root">
      <div className="player-select-editor-players">
        {team?.persons?.map((player) => {
          const isExcluded = excludedPlayers.includes(player.personId);
          const isSelected = event.personId === player.personId;
          const className = "player-select-editor-player "
            + (team && lightOrDark(team.primaryColor))
            + (isExcluded ? " excluded " : "")
            + (isSelected ? " selected" : "");
          return (
            <div
              key={player.bib}
              className={className}
              style={{
                backgroundColor: team.primaryColor,
              }}
              onClick={() => {
                onPlayerSelect(player);
              }}
            >
              {player.bib}
            </div>
          );
        })}
      </div>
      {showTeamAssign && (
        <div
          className={"player-select-editor-team " +
            (team && lightOrDark(team.primaryColor))}
          style={{
            backgroundColor: team.primaryColor,
          }}
          onClick={onTeamAssign}
        >
          {formatMessage({
            id: "edit.assign.team",
            defaultMessage: "ASSIGN TO TEAM",
          })}
        </div>
      )}
    </div>
  );
};

export default injectIntl(PlayerSelectEditor);
