import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { v1 as uuidv1 } from "uuid";
import { useDoActions } from "../../../../Base/Hooks/doActions";
import { getEventTime } from "../../../../Base/Utilities/SendEvent";
import PlayerSelect from "../../../PlayerSelect/PlayerSelect";
import FreeThrowResult from "./FreeThrowResult";

import "./Freethrow.scss";

const SimpleFreeThrows = (props) => {
  const { currentState, panel, intl, updateState } = props;
  const { formatMessage } = intl;
  const [freeThrows, setFreeThrows] = useState();
  const [freeThrowResults, setFreeThrowResults] = useState([]);
  const [freeThrowSuccesses, setFreeThrowSuccesses] = useState([]);
  const [newResult, setNewResult] = useState(null);
  const [freeThrowsDone, setFreeThrowsDone] = useState(false);
  const [playId, setPlayId] = useState();
  const [entityId, setEntityId] = useState();
  const [shooter, setShooter] = useState(null);
  const [done, setDone] = useState(false);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
  }, [actionsDone]);

  useEffect(() => {
    if (done) {
      let currentEvents = currentState.events;
      currentEvents["shot"] = JSON.parse(JSON.stringify(currentState.events["main"]));

      currentEvents.main["success"] = freeThrowResults[freeThrowResults.length - 1].success;
      updateState("events", currentEvents);

      setDoAction(true);
    }

    // eslint-disable-next-line
  }, [done]);

  useEffect(() => {
    const newPlayId = uuidv1();
    updateState("playId", newPlayId);
    setPlayId(newPlayId);
    const newMainEvent = buildNewMainEvent(currentState.events, newPlayId);
    newMainEvent.options.successes =
      currentState.events.main?.options?.successes && currentState.events.main.options.successes.length > 0
        ? currentState.events.main.options.successes
        : freeThrowSuccesses;
    updateState("events", { ...currentState.events, main: newMainEvent });

    const freeThrowsCount =
      currentState.events.main?.options?.freeThrows > 0 ? currentState.events.main.options.freeThrows : 0;

    setFreeThrowSuccesses(
      currentState.events.main.options.successes.length > 0
        ? currentState.events.main.options.successes
        : new Array(freeThrowsCount).fill(null),
    );

    setFreeThrows(freeThrowsCount);
    setShooter(currentState.events["fouldrawn"]?.personId ?? null);

    currentState.events["freethrow"] = { ...currentState.events["main"] };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newResults = [...Array(freeThrows).keys()].map((itemIndex) => {
      const wasItemSuccess = freeThrowResults[itemIndex]?.success ?? null;
      return { success: wasItemSuccess };
    });
    setFreeThrowResults(newResults);
    // eslint-disable-next-line
  }, [freeThrows]);

  useEffect(() => {
    let tempEntity = {};
    let currentEntityEvent = panel.options.currentEntityEvent ? panel.options.currentEntityEvent : "main";
    if (panel.options.currentEntity === true) {
      tempEntity = currentState.entities.filter(
        (team) => team.entityId === currentState.events[currentEntityEvent].entityId,
      )[0];
    } else if (panel.options.currentEntity === false) {
      tempEntity = currentState.entities.filter(
        (team) => team.entityId !== currentState.events[currentEntityEvent].entityId,
      )[0];
    } else {
      tempEntity = currentState.entities.filter((team) => team.entityId !== null)[panel.options.team];
    }
    setEntityId(tempEntity.entityId);
    // eslint-disable-next-line
  }, [panel]);

  useEffect(() => {
    if (newResult) {
      const newFreeThrowResults = [...freeThrowResults];
      newFreeThrowResults[newResult.index].success = newResult.result;
      setFreeThrowResults(newFreeThrowResults);
      setNewResult(null);
    }
    // eslint-disable-next-line
  }, [newResult]);

  useEffect(() => {
    if (freeThrowResults.filter((res) => res.success !== null).length < freeThrowResults.length) {
      setFreeThrowsDone(false);
    } else {
      setFreeThrowsDone(true);
    }
  }, [freeThrowResults]);

  const _formatClock = (displayClock) => {
    let clockParts = displayClock.split(":");
    return "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S";
  };

  const buildNewMainEvent = (currentEvents, playId) => {
    let newOptions = currentEvents["main"].options;
    if (!newOptions) newOptions = {};
    delete newOptions.shooting;
    const newMainEvent = {
      ...currentEvents["main"],
      playId,
      eventTime: getEventTime(),
      clock: _formatClock(currentState.clock.displayTime),
      periodId: currentState.period.periodId,
      eventId: uuidv1(),
      eventType: "freeThrow",
      entityId: currentState.entities.find((el) => el.entityId !== currentState.events.foul.entityId).entityId,
      options: newOptions,
    };
    delete newMainEvent["subType"];
    return newMainEvent;
  };

  const updateShooter = (person) => {
    setShooter(person.personId);
  };

  const updateResults = (index, result) => {
    setNewResult({ index: index, result: result });
  };

  const addNewFreeThrow = () => {
    setFreeThrows(freeThrows + 1);
  };

  const allowAdding2nd = panel.options?.allowAddingNew && freeThrows === 1;
  const allowAdding3rd = panel.options?.allowAddingNew && freeThrows === 2;

  return (
    <React.Fragment>
      <div className="simple-free-throws">
        <div className="panels">
          <div>
            {freeThrowSuccesses.length > 0 &&
              [...Array(freeThrows).keys()].map((itemIndex) => (
                <FreeThrowResult
                  {...props}
                  key={itemIndex + 1}
                  item={itemIndex + 1}
                  total={freeThrows}
                  shooter={shooter}
                  updateResults={updateResults}
                  entityId={entityId}
                  playId={playId}
                  wasSuccess={freeThrowSuccesses[itemIndex]}
                />
              ))}
            {allowAdding2nd && (
              <div className="match-button action enabled-true" onClick={addNewFreeThrow}>
                {formatMessage({
                  id: "freethrows.add2nd",
                  defaultMessage: "Add 2nd",
                })}
              </div>
            )}
            {allowAdding3rd && (
              <div className="match-button action enabled-true" onClick={addNewFreeThrow}>
                {formatMessage({
                  id: "freethrows.add3rd",
                  defaultMessage: "Add 3rd",
                })}
              </div>
            )}
          </div>
          <div className="player-select">
            <h4>
              {formatMessage({
                id: "player.shooting",
                defaultMessage: "Shooting player",
              })}
              :
            </h4>
            <PlayerSelect {...props} update={updateShooter} selectedPlayer={shooter} />
          </div>
        </div>
        <div className={"match-button action enabled-" + freeThrowsDone} onClick={() => setDone(true)}>
          {formatMessage({
            id: "done",
            defaultMessage: "Done",
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(SimpleFreeThrows);
