export const TurnoverPlayerSelect = {
  name: "turnover-player-select",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "turnover.select-player",
  showOn: [{ state: "primary", value: "turnover-player-select", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "turnover-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: false,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "nextState", value: "steal-player-select", type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "team-turnover-button",
      component: "TypeButton",
      layout: "match-button center",
      mode: "enable",
      text: "turnover.team",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: null, type: "personId" },
            { action: "saveData", value: [{ option: "team", value: true }], type: "options" },
            { action: "nextState", value: "steal-player-select", type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
