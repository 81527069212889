export const ModifyShotTypeSelect = {
  name: "modify-shot-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "shot.type",
  showOn: [{ state: "editState", value: "shot-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "2pt-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [{ state: "event.eventType", value: "2pt", operator: "is", event: "edit" }],
      options: {
        type: "edit",
        value: "subType",
        children: ["jumpShot", "layup", "dunk", "confirmLater"],
        layout: "type-button",
      },
      actionGroups: [],
    },
    {
      name: "3pt-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [{ state: "event.eventType", value: "3pt", operator: "is", event: "edit" }],
      options: {
        type: "edit",
        value: "subType",
        children: ["jumpShot", "confirmLater"],
        layout: "type-button",
      },
      actionGroups: [],
    },
    {
      name: "confirm-panel",
      component: "SubPanel",
      layout: "sub-panel",
      mode: "visible",
      text: null,
      showOn: [true],
      children: [
        {
          name: "ok-button",
          component: "TypeButton",
          layout: "match-button center action",
          mode: "enable",
          text: "ok",
          showOn: [{ state: "event.subType", value: null, operator: "not", event: "edit" }],
          actionGroups: [
            {
              actions: [
                { action: "clearData", value: ["subType"], type: "edit" },
                { action: "saveData", type: "flagged", value: true, event: "edit" },
              ],
              conditions: [{ item: "subType", value: "confirmLater", event: "edit", operator: "is" }],
            },
            {
              actions: [{ action: "saveData", type: "flagged", value: false, event: "edit" }],
              conditions: [{ item: "subType", value: "confirmLater", event: "edit", operator: "not" }],
            },
            {
              actions: [
                { action: "sendEvent", value: "edit", type: "edit" },
                { action: "nextState", value: null, type: "editState" },
              ],
              conditions: [],
            },
          ],
        },
      ],
    },
  ],
};
