import React, { useEffect, useState, useContext } from "react";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";

import { useIsVisible } from "../../Base/Hooks/IsVisible";
import { useDoActions } from "../../Base/Hooks/doActions";

import "./NewPositionSelect.scss";

import ice3Rink from "../../../assets/ice3/rink.png";

import basketballCourt from "../../../assets/basketball/surface.png";
import basketballInsertCourt from "../../../assets/basketball/surface-insert.png";
import basketballRegions from "../../../assets/basketball/regions.png";
import HockeyField from "./hockey/Field";
import IndoorHockeyField from "./hockey/IndoorField";
import HandballCourt from "./handball/Court";
import Hockey5sField from "./hockey/Hockey5sField";
import { HoverEventContext } from "../../../Config/HoverEvent";
import PositionHelper from "../../Base/Utilities/PositionHelper.js";

const NewPositionSelect = (props) => {
  const { currentState, updateState, panel } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);
  const [isInsertMode, setIsInsertMode] = useState(false);

  const [markTop, setMarkerTop] = useState(0);
  const [markLeft, setMarkerLeft] = useState(0);
  const [hoverMarkVisible, setHoverMarkVisible] = useState(false);
  const [hoverMarkTop, setHoverMarkTop] = useState(0);
  const [hoverMarkLeft, setHoverMarkLeft] = useState(0);

  const { sport, season } = useContext(FixtureDetailsContext);
  const { hoverEvent } = useContext(HoverEventContext);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
  }, [actionsDone]);

  useEffect(() => {
    if (currentState.insertMode && currentState.insertMode.enabled === true) {
      setIsInsertMode(true);
    } else {
      setIsInsertMode(false);
    }
  }, [currentState.insertMode]);

  useEffect(() => {
    if (hoverEvent && hoverEvent.x && hoverEvent.y) {
      setHoverMarkLeft(hoverEvent.x - 0.5);
      setHoverMarkTop(hoverEvent.y - 1.75);
      setHoverMarkVisible(true);
    } else {
      setHoverMarkVisible(false);
      setHoverMarkLeft(0);
      setHoverMarkTop(0);
    }
  }, [hoverEvent]);

  function getPos(ev) {
    const offsetWidth = ev.target.offsetWidth ?? ev.target.parentElement.offsetWidth;
    const offsetHeight = ev.target.offsetHeight ?? ev.target.parentElement.offsetHeight;
    const markerPosX = parseFloat(((ev.nativeEvent.offsetX / offsetWidth) * 100).toFixed(2));
    const markerPosY = parseFloat(((ev.nativeEvent.offsetY / offsetHeight) * 100).toFixed(2));

    updateState("pos", { x: markerPosX, y: markerPosY });

    if (sport === "handball" || sport === "basketball") {
      updateState("region", PositionHelper.getPoint(markerPosX, markerPosY, sport));
    }

    setMarkerLeft(markerPosX - 0.5);
    setMarkerTop(markerPosY - 1.75);
    updateState("markerPos", { x: markerPosX, y: markerPosY });

    setDoAction(true);
    setIsOpen(true);
  }

  return isVisible ? (
    <div className={panel.layout + " enabled-" + isEnabled + " insertMode-" + isInsertMode}>
      <div className="base-play-area-container">
        {sport === "ice3" && (
          <img id="base-play-area" alt="base-play-area" src={ice3Rink} onClick={getPos} draggable={false} />
        )}
        {sport === "basketball" && (
          <>
            <img
              id="base-play-area"
              alt="base-play-area"
              src={isInsertMode ? basketballInsertCourt : basketballCourt}
              onClick={getPos}
              draggable={false}
            />
            <img
              id="base-play-area-regions"
              alt="base-play-area-regions"
              style={{ display: "none" }}
              src={basketballRegions}
            />
          </>
        )}
        {sport === "hockey" && season.discipline === "HOCKEY5S" && <Hockey5sField {...props} />}
        {sport === "hockey" && season.discipline === "OUTDOOR" && <HockeyField {...props} />}
        {sport === "hockey" && season.discipline === "INDOOR" && <IndoorHockeyField {...props} />}
        {sport === "handball" && <HandballCourt onClick={getPos} props={props} />}
        {isOpen && !hoverMarkVisible && (
          <div
            className="court-mark"
            style={{ top: markTop + "%", left: markLeft + "%" }}
            onClick={(e) => e.stopPropagation()}
          ></div>
        )}
        {hoverMarkVisible && (
          <div
            className="court-mark"
            style={{ top: hoverMarkTop + "%", left: hoverMarkLeft + "%" }}
            onClick={(e) => e.stopPropagation()}
          />
        )}
        <div id="FloatingPanelContainer" />
      </div>
    </div>
  ) : null;
};

export default NewPositionSelect;
