import React, { useContext, useEffect, useMemo, useState } from "react";
import { injectIntl } from "react-intl";
import { convertEventTimeClock } from "../../Base/Utilities/ConvertClock";
import MainPanel from "../MainPanel/MainPanel";
import Close from "./Close/Close";
import EditBookMark from "./EditBookMark/EditBookMark";
import EditList from "./EditList/EditList";
import "./EditPanel.scss";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails.jsx";
import { generatePeriodTitle } from "../../Base/Utilities/Game.js";

const EditPanel = (props) => {
  const { currentState, workFlow, intl, updateLog, playStore, eventStore } =
    props;
  const { formatMessage } = intl;
  const [editPlay, setEditPlay] = useState();
  const [showList, setShowList] = useState(true);
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);

  useEffect(() => {
    if (currentState.modify) {
      let currentPlay = playStore.filter(
        (play) => play.playId === currentState.modify.playId
      );
      setEditPlay(currentPlay[0]);
      let editEvents = eventStore.filter(
        (event) => event.playId === currentState.modify.playId
      );
      if (editEvents) {
        editEvents.forEach((event, index) => {
          currentState.events["edit-" + index] = JSON.parse(
            JSON.stringify(editEvents[index])
          );
        });
      }
    }
    // eslint-disable-next-line
  }, [props]);

  const editPanels = useMemo(() => {
    if (currentState.modify && workFlow) {
      return workFlow.modify.panels.map((panel, index) => (
        <React.Fragment key={index}>
          {(function () {
            switch (panel.component) {
              case "MainPanel":
                return <MainPanel {...props} panel={panel} />;
              default:
                return null;
            }
          })()}
        </React.Fragment>
      ));
    }
    // eslint-disable-next-line
  }, [workFlow, props]);

  return (
    <React.Fragment>
      {currentState.modify && editPlay && (
        <div className={`edit-panel showList-${showList}`}>
          <div>
            <h3>
              {formatMessage({
                id: "edit.play",
                defaultMessage: "Edit play",
              })}
              :
              <span className="period-details">
                {generatePeriodTitle(sport, editPlay.periodId, fixtureProfile, formatMessage, false)}
              </span>
              {editPlay.clock && (
                <span className="play-clock">
                  {convertEventTimeClock({
                    clock: editPlay.clock,
                    periodId: editPlay.periodId,
                    sport,
                    fixtureProfile,
                  })}
                </span>
              )}
              <div className="panel-extras">
                <EditBookMark {...props} />
                <Close {...props} />
              </div>
            </h3>
            <EditList
              {...props}
              updateLog={updateLog}
              setShowList={setShowList}
            />
          </div>
        </div>
      )}
      {editPanels}
    </React.Fragment>
  );
};

export default injectIntl(EditPanel);
